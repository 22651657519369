import { Getter } from "vuex";
import { IState } from './state';

const card: Getter<IState, IState> = (state) => state.card;
const translation: Getter<IState, IState> = (state) => state.translation;
const options: Getter<IState, IState> = (state) => state.options;

export default {
	card,
	translation,
	options
}